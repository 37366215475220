<template>
    <div v-if="hasMenu">
        <v-app-bar-nav-icon color="primary" @click.stop="onClickNavIcon" class="nav-icon"/>
        <v-dialog v-model="dialog" fullscreen transition="dialog-bottom-transition">
            <v-card tile>
                <v-img eager :src="require('@/assets/img/doctors.png')" max-height="150" class="background-img">
                    <v-img eager :src="require('@/assets/logos/logo_white.png')" max-width="200"
                           class="p-absolute-center"/>
                </v-img>
                <v-card-text>
                    <v-row class="mt-3">
                        <v-col cols="12">
                            <v-app-bar-nav-icon color="primary" @click.stop="hide"/>
                        </v-col>
                        <v-col cols="6" v-for="(option, i) in options" :key="i">
                            <v-sheet
                                :color="option.route ? 'primary' : '#b2b2b2'"
                                :dark="!!option.route" v-ripple="option.route"
                                :class="['btn-container', {'pointer': option.router}]"
                                @click="onClickOption(option.route)"
                            >
                                <v-icon v-text="option.icon" size="80"/>
                                <br>
                                {{ option.name }}
                            </v-sheet>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6" class="text-center">
                            <v-btn color="secondary" x-large depressed class="normal-btn" @click="copyLink">
                                Copiar link
                            </v-btn>
                        </v-col>
                        <v-col cols="6" class="text-center">
                            <logout-button/>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import LogoutButton from "@/components/common/LogoutButton";

export default {
    name: "MenuDialog",
    components: {LogoutButton},
    data() {
        return {
            dialog: false,
        }
    },
    computed: {
        ...mapState(['dialogActive']),
        hasMenu(vm = this) {
            return vm.isLoggedIn && !vm.isClient && vm.options.some(option => option.route !== vm.$route.name) || false
        },
        options(vm = this) {
            let options = [
                {name: 'Editar Perfil', icon: '$profile_circle', route: `${vm.accountType}.profile.edit`},
                {name: 'Ver estadísticas', icon: '$statistics', route: `${vm.accountType}.statistics`},
                {name: 'Facturas', icon: '$invoice', route: null},
            ]

            if (vm.isDoctor || vm.isAssistant)
                options.push({
                    name: 'Respuesta pac.',
                    icon: '$response',
                    route: `${vm.accountType}.responses`
                })

            if (vm.isDoctor)
                options.push(
                    {name: 'Editar equipo', icon: '$team', route: `${vm.accountType}.team`},
                    {name: 'Convenios', icon: '$laboratory', route: `${vm.accountType}.laboratories`}
                )

            return options
        },
        doctorId(vm = this) {
            return vm.currentUser?.doctor_id || vm.currentUser?.id || null
        }
    },
    methods: {
        ...mapMutations(['setDialogActive']),
        onClickNavIcon() {
            const vm = this
            vm.dialog ? vm.hide() : vm.show()
        },
        show() {
            const vm = this
            vm.dialog = true
            vm.setDialogActive(true)
        },
        hide() {
            const vm = this
            vm.dialog = false
            vm.setDialogActive(false)
        },
        onClickOption(route) {
            const vm = this
            if (!route) return;
            if (vm.$route.name === route) return vm.hide()
            vm.goTo({name: route})
        },
        copyLink() {
            const vm = this;
            const link = `${vm.baseUrlFront}client/${vm.doctorId}/register`;
            navigator.clipboard.writeText(link)
        },
    },
    watch: {
        dialogActive(val) {
            if (!val) this.hide()
        },
    }
}
</script>

<style scoped>

.nav-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.background-img {
    border-bottom-left-radius: 80% 60px;
    border-bottom-right-radius: 80% 60px;
}

.background-img:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--v-secondary-base);
    opacity: .7;
}

.btn-container {
    border-radius: 12px;
    text-align: center;
    padding: 12px 0;
}

</style>