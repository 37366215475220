<template>
    <v-container :class="[{'pa-0': pa0}]">
        <v-row class="mt-5">
            <v-col cols="12" class="p-relative">
                <menu-dialog/>
                <v-img class="mx-auto" width="180" :src="img" contain/>
            </v-col>
            <v-col :cols="noFullBody ? 10 : 12" :offset="noFullBody ? 1 : 0" class="my-7">
                <slot name="body"/>
            </v-col>
            <v-col cols="12" class="text-center">
                <slot name="buttons"/>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import MenuDialog from "@/components/common/MenuDialog";
export default {
    name: "MasterPanel",
    components: {MenuDialog},
    props: {
        noFullBody: {
            type: Boolean,
            default: false,
        },
        pa0: {
            type: Boolean,
            default: false,
        },
        dark: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        img(vm = this) {
            return vm.dark ? vm.imgLogo : vm.imgLogoDark
        }
    }
}
</script>

<style scoped>

</style>